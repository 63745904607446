@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sidebar {
  .sidebar-heading {
    font-size: 0.75rem;
  }

  .nav-link {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--bs-primary);

    &.active {
      color: var(--bs-secondary);
    }
  }
}

@include media-breakpoint-up(md) {
  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    height: 100vh;
  }
  .navbar-search {
    display: block;
  }
}

@include color-mode(dark) {
  .nav-link {
    color: white
  }
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
}
